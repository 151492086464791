import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const TransfusionalModalStyles = styled(Modal)`
  display: flex !important;
  align-items: center;
  .modal {
    &-dialog {
      max-width: 90vw;
      width: 100%;
    }
    &-content {
      width: 100%;
      padding: 15px;
      .title {
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        &:last-of-type {
          margin-bottom: 12px;
        }
        &.main {
          font-weight: bold;
        }
      }

      .btn {
        padding: 4px 20px;
        &-primary {
          background-color: #0064a9;
        }
      }
    }

    &-body {
      padding: 0;

      table {
        width: 100%;
      }

      section {
        padding: 8px 10px;
        margin-bottom: 10px;
      }

      input {
        border: none;
      }
    }

    &-footer {
      border-top: none;
      justify-content: flex-end;
      padding: 0px;
    }

    &-input {
      border: 1px solid #d9d9d9;
      padding: 14px;
      border-radius: 4px;
      color: #4f4f4f;
      width: 100%;
      outline: none;
      &-wrapper {
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;
      }
      &-btn {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
      }
    }
  }
`
