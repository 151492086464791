import styled from 'styled-components'

export const TimeLineStyles = styled.div`
  margin: 0px 60px;
  border-bottom: 2px solid #c1c1c1;
`
export const Steps = styled.ul`
  display: flex;
  justify-content: space-around;
  padding-left: 0px;
  margin-bottom: 0px;
`

export const Step = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  .label {
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 6px;
  }
  .timestamp {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: #868686;
    bottom: 0px;
    transform: translateY(50%);
    &.active {
      background-color: #004371;
      &::before {
        background-color: #004371;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 1px solid white;
      background-color: #868686;
    }
  }
`