import { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { ReactComponent as ElipsesIcon } from '../../assets/icons/elipses.svg'
import { DropdownStyles } from './styles'
import { ActionDropdownProps, ActionsType } from './types'

const ACTIONS: ActionsType[] = [
  { value: 'rt', label: 'Visualizar RT' },
  { value: 'sadt', label: 'Visualizar Guia SADT', off: true },
  { value: 'termo-consent', label: 'Termo de consentimento', off: true },
  { value: 'etiq-tipagem', label: 'Gerar etiqueta para tipagem' },
  { value: 'saida-hemocomp', label: 'Gerar saída de hemocomponente', off: true },
  { value: 'recibo-hemocomp', label: 'Gerar recebimento de hemocomponente', off: true },
  { value: 'acomp-transf', label: 'Acompanhamento Transfusional', off: true },
  { value: 'hemovigilancia', label: 'Hemovigilância', off: true },
]

export const ActionsDropdown: FC<ActionDropdownProps> = ({ onSelect, disabled }) => (
  <DropdownStyles onSelect={onSelect}>
    <Dropdown.Toggle disabled={disabled}>
      <ElipsesIcon />
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {ACTIONS.map(({ value, label, off }) =>
        !off ? (
          <Dropdown.Item
            onClick={(e: any) => e.preventDefault()}
            key={value}
            href={value}
            disabled={off}
          >
            {label}
          </Dropdown.Item>
        ) : null,
      )}
    </Dropdown.Menu>
  </DropdownStyles>
)
