/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { validateToken } from '../../service'
import { AuthContextProps, AuthContextTypes, AuthTypes } from './types'

const AuthContext = createContext<AuthContextTypes>({
  auth: null,
  setAuth: () => null,
  appKey: '',
  setAppKey: () => null,
  token: '',
  setTokenInfo: () => null,
  acessos: null,
  tokenInfo: null,
  clearAuth: () => null,
  suggestions: [],
})

export const useAuthContext = () => useContext(AuthContext)

export const AuthContextProvider: FC<AuthContextProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthTypes | null>(null)
  const [appKey, setAppKey] = useState('')
  const [token, setToken] = useState('')
  const [tokenInfo, setTokenInfo] = useState<any>(null)
  const [acessos, setAcessos] = useState<any>(null)
  const [suggestions, setSuggestions] = useState<string[]>([])

  const doValidateToken = async () => {
    try {
      const { data } = await validateToken()
      setTokenInfo(JSON.parse(data.content))
    } catch (error) {
      console.error(error)
    }
  }

  const clearAuth = () => {
    setToken('')
    setTokenInfo(null)
    setAcessos(null)
    sessionStorage.setItem('sgbs_token', '')
    sessionStorage.setItem('sgbs_appkey', '')
    sessionStorage.setItem('dashboard_filter', '')

  }

  useEffect(() => {
    setToken(auth?.token || '')
  }, [auth])

  useEffect(() => {
    setAcessos(tokenInfo?.acessos[0])
  }, [tokenInfo])

  useEffect(() => {
    const tempSuggestions = []
    acessos?.operador?.filiais
      .sort((a, b) => a.filial.codigo.localeCompare(b.filial.codigo))
      .map((item) => {
        tempSuggestions.push(`${item.filial.codigo} - ${item.filial.descricao}`)
        return {
          ...item,
          value: item.filial.codigo,
          label: `${item.filial.codigo} - ${item.filial.descricao}`,
        }
      })

    setSuggestions(tempSuggestions)
  }, [acessos])

  useEffect(() => {
    if (token && appKey) {
      sessionStorage.setItem('sgbs_token', token)
      sessionStorage.setItem('sgbs_appkey', appKey)
    }
  }, [token, appKey])

  useEffect(() => {
    if (appKey && token) {
      doValidateToken()
    }
  }, [appKey, token])

  const removeSearchCache = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'r') {
      sessionStorage.setItem('dashboard_filter', '')
    }
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('sgbs_token'))
    setAppKey(sessionStorage.getItem('sgbs_appkey'))

    window.addEventListener("keydown", removeSearchCache);
    return () => {
      window.removeEventListener("keydown", removeSearchCache);
    };
  }, [])

  const authValues = useMemo(
    () => ({
      auth,
      setAuth,
      appKey,
      setAppKey,
      token,
      setTokenInfo,
      acessos,
      tokenInfo,
      clearAuth,
      suggestions,
    }),
    [setAuth, auth, setAppKey, appKey, token, acessos, tokenInfo, suggestions],
  )

  return <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
}
