import { CustomCheckboxStyles } from './styles'

export const CustomCheckbox = ({
  label,
  checked,
  disabled,
}: {
  label: string
  checked: boolean
  disabled: boolean
}) => (
  <CustomCheckboxStyles htmlFor={label} className='container'>
    {label}
    <input name={label} disabled={disabled} type='checkbox' checked={checked} />
    <span className='checkmark' />
  </CustomCheckboxStyles>
)
