/* eslint-disable react/display-name */
import { format } from 'date-fns'
import { forwardRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatNumber, getDateModalityTransf, getField } from '../../utils'
import { maskCpfOrCnpj, maskRg } from '../../utils/mask'
import { SubHeader, SubTitle } from '../page/styles'
import { PdfBorder, PdfLabel, PdfTable, PdfText, PdfTitle, PdfWrapper } from './styles'

export const PdfTransfustionRequest = forwardRef<any>((data: any, ref) => (
  <PdfWrapper ref={ref}>
    <PdfBorder>
      <PdfTitle>REQUISIÇÃO DE TRANSFUSÃO DE SANGUE</PdfTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 6,
          marginBottom: 6,
        }}
      >
        <h3 style={{ fontSize: 14 }}>
          Hospital:&nbsp;
          <span style={{ fontWeight: 'normal', fontSize: 16 }}>{getField('unityName', data)}</span>
        </h3>
        <span style={{ fontWeight: 'normal', fontSize: 16 }}>
          Nº Transfusão: #{getField('id', data)}
        </span>
      </div>
      <hr style={{ marginBottom: 8 }} />
      <SubHeader style={{ marginBottom: 6 }}>
        <SubTitle>DADOS DO PACIENTE</SubTitle>
        <SubTitle>N° Atend.: {getField('attendanceCode', data)}</SubTitle>
      </SubHeader>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={6}>
          <PdfLabel>Nome do Paciente:</PdfLabel>
          <PdfText>{getField('patientName', data)}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Data de Nasc.:</PdfLabel>
          <PdfText>{format(new Date(data.patientBornDate), 'dd/MM/yyyy')}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Idade:</PdfLabel>
          <PdfText>{`${Math.floor(
            (Date.now() - new Date(data.patientBornDate).getTime()) / 31557600000,
          )} anos`}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Sexo:</PdfLabel>
          <PdfText>{`${getField('patientSex', data) === 'F' ? 'Feminino' : 'Masculino'}`}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Peso:</PdfLabel>
          <PdfText>{`${getField('patientWeight', data)} kg`}</PdfText>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>CPF:</PdfLabel>
          <PdfText>{maskCpfOrCnpj(getField('patientCpf', data))}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>RG:</PdfLabel>
          <PdfText>{maskRg(getField('patientRg', data))}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Outros docs:</PdfLabel>
          <PdfText />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Telefone:</PdfLabel>
          <PdfText />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <PdfLabel>Nome da Mãe:</PdfLabel>
          <PdfText>{getField('patientMar', data)}</PdfText>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Convênio:</PdfLabel>
          <PdfText>{getField('healthInsuranceName', data)}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={5}>
          <PdfLabel>Matrícula Convênio:</PdfLabel>
          <PdfText>{getField('healthInsuranceWallet', data)}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Nª GIH/AIH: <span />
          </PdfLabel>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={6}>
          <PdfLabel>
            Clínica: <span />
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Enfermaria/APT°:
            <span />
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Leito:
            <span />
          </PdfLabel>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Diagnóstico:</PdfLabel>
          <p style={{ fontSize: 14, marginBottom: 4 }}>{`${getField(
            'attendanceCodeCid',
            data,
          )} - ${getField('attendanceCid', data)}`}</p>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Indicação da transfusão:</PdfLabel>
          <PdfText>{getField('useIndicationDescription', data).split(',').join(', ')}</PdfText>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Hb: <span>{`${formatNumber(getField('resultHbDescription', data))} g/DL`}</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Ht: <span>{`${formatNumber(getField('resultHtDescription', data))} %`}</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>
            Plaq: <span>{`${formatNumber(getField('resultPlaqDescription', data))}/mm³`}</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <PdfLabel>
            TAP/INR: <span>{getField('resultTapDescription', data)}</span>
          </PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <PdfLabel>
            FIBR: <span>{getField('resultFibrDescription', data)}</span>
          </PdfLabel>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Modalidade Transfusão:</PdfLabel>
          <PdfText>{getField('modalityTransfName', data)}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Data:</PdfLabel>
          <PdfText>{getDateModalityTransf(data, getField)}</PdfText>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Hist. de transfusão prévia:</PdfLabel>
          <PdfText />
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>Há quanto tempo:</PdfLabel>
          <PdfText />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between' md={4}>
          <PdfLabel>Reação Transfusional Prévia:</PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={2}>
          <PdfLabel>Tipo:</PdfLabel>
        </Col>
        <Col className='d-flex flex-dir-col space-between' md={3}>
          <PdfLabel>História Gestacional:</PdfLabel>
        </Col>
      </Row>
      <hr />
      <SubTitle>SOLICITAÇÃO</SubTitle>
      <Row>
        <Col md={8}>
          <PdfTable style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Hemocomponente solicitado </th>
                <th>Quantidade</th>
                <th>Volume</th>
              </tr>
            </thead>
            <tbody>
              {data.items.map((item) => (
                <tr key={item.id} className='mb-10'>
                  <td
                    style={{
                      textDecoration: !item.statusFlagSuspensao ? 'line-through underline' : 'none',
                    }}
                  >
                    {item.bloodComponentName}
                  </td>
                  <td
                    style={{
                      textDecoration: !item.statusFlagSuspensao ? 'line-through underline' : 'none',
                      textAlign: 'center',
                    }}
                  >
                    {formatNumber(item.qtyDosage)}
                  </td>
                  <td
                    style={{
                      textDecoration: !item.statusFlagSuspensao ? 'line-through underline' : 'none',
                      textAlign: 'center',
                    }}
                  >
                    {item.unitMeasure.toLowerCase()}
                  </td>
                </tr>
              ))}
            </tbody>
          </PdfTable>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Procedimentos Especiais:</PdfLabel>
          <PdfText>{getField('specialProced', data).split(',').join(', ')}</PdfText>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex flex-dir-col space-between'>
          <PdfLabel>Justificativa dos procedimentos especiais:</PdfLabel>
          <PdfText>{getField('specialProcedJustDescription', data)}</PdfText>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col md={11}>
          <PdfTable style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Etiqueta Amostra</th>
                <th style={{ width: '20%' }}>Data</th>
                <th style={{ width: '20%' }}>Colhida</th>
                <th style={{ width: '40%' }}>Responsável pela coleta</th>
              </tr>
            </thead>
            <tbody>
              <tr className='mb-10' style={{ height: 30 }}>
                <td className='border' />
                <td className='border' />
                <td className='border' />
                <td className='border' />
              </tr>
            </tbody>
          </PdfTable>
        </Col>
      </Row>
      {/* <PdfTable className='mt-2'>
          <thead>
            <tr>
              <th>Data</th>
              <th>Hemocomp.</th>
              <th>Quantidade</th>
              <th>Volume</th>
              <th>Cód. Produto</th>
              <th>Nº Bolsa</th>
              <th>FLAG</th>
              <th>ABO/Rh</th>
              <th>PC</th>
              <tr>
                <th colSpan={2}>Entrega/Liberação</th>
                <th colSpan={3}>Transfusão</th>
              </tr>
              <tr>
                <th>Hora</th>
                <th>Técnico</th>
                <th>SIM</th>
                <th>NÃO**</th>
                <th>Data</th>
              </tr>
            </tr>
          </thead>
          <tbody>
            {data?.items.map((item) => (
              <tr key={item.id}>
                <td>{format(new Date(item.registrationDate), 'dd/MM/yyyy')}</td>
                <td>{item.bloodComponentName}</td>
                <td>{formatNumber(item.qtyDosage)}</td>
                <td>{item.unitMeasure.toLowerCase()}</td>
                <td>{item.productCode}</td>
                <td />
                <td>{item.statusFlagSuspensao ? 'Sim' : 'Não'}</td>
                <td />
                <td />
                <td colSpan={5} />
                <td colSpan={3} />
              </tr>
            ))}
          </tbody>
        </PdfTable> */}
      <Row style={{ position: 'absolute', bottom: 20, left: '5%', right: '5%' }}>
        <Row style={{ marginTop: 40, justifyContent: 'space-between' }}>
          <Col className='d-flex' style={{ alignItems: 'flex-end' }} md={6}>
            <hr style={{ width: '100%' }} />
          </Col>
          <Col md={2}>
            <p style={{ fontSize: 12, marginBottom: 4, fontWeight: 'bold' }}>
              Data:&nbsp;{format(new Date(data.registrationDate), 'dd/MM/yyyy')}
            </p>
          </Col>
          <Col md={2}>
            <p style={{ fontSize: 12, marginBottom: 4, fontWeight: 'bold' }}>
              Hora:&nbsp;{format(new Date(data.registrationDate), 'HH:mm')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <p style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0, fontSize: 12 }}>
              {getField('doctorName', data)}
            </p>
            <p
              style={{
                fontSize: 12,
                marginBottom: 4,
                marginTop: 0,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              CRM:&nbsp;{getField('doctorCrmNumber', data)}
            </p>
          </Col>
        </Row>
      </Row>
    </PdfBorder>
  </PdfWrapper>
))
