import { useEffect, useState } from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'
import { Block } from '../../components'
import { PageHeader, PageTitle } from '../../components/page/styles'
import { ProcedureTab } from '../../components/parametrizacao/ProcedureTab'
import { UnityTab } from '../../components/parametrizacao/UnityTab'
import {
  getAuthorizationUnits,
  getAuthorizationUnitsData,
  getCodeProceedingsAuthorization,
} from '../../service'

export const ParametrizacaoPage = () => {
  const [unitiesData, setUnitiesData] = useState([])
  const [proceduriesData, setProceduriesData] = useState([])
  const [unities, setUnities] = useState([])

  const loadData = async () => {
    try {
      const responseU = await getAuthorizationUnitsData()
      const responseP = await getCodeProceedingsAuthorization()
      const responseUnities = await getAuthorizationUnits()

      const tempUnities = responseUnities?.data?.content

      setUnitiesData(
        responseU?.data?.content.map((item) => ({
          ...item,
          unitId: tempUnities.find((fItem) => fItem.id === item.unitCode)?.id,
        })) || [],
      )
      setProceduriesData(
        responseP?.data?.content.map((item) => ({
          ...item,
          unitId: tempUnities.find((fItem) => fItem.id === item.unitCode)?.id,
        })) || [],
      )
      setUnities(tempUnities || [])
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Block>
      <PageHeader>
        <PageTitle className='text-uppercase'>Parametrização de Autorização</PageTitle>
      </PageHeader>
      <Container fluid>
        <Tabs
          onSelect={loadData}
          defaultActiveKey='Unidade'
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          <Tab eventKey='Unidade' title='Unidade'>
            <UnityTab data={unitiesData} unities={unities} onSuccess={loadData} />
          </Tab>
          <Tab eventKey='Procedimento' title='Procedimento'>
            <ProcedureTab data={proceduriesData} unities={unities} onSuccess={loadData} />
          </Tab>
        </Tabs>
      </Container>
    </Block>
  )
}
