import { FC, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Autocomplete } from '..'
import { createAuthorizationUnit, updateAuthorizationUnit } from '../../service'
import { DocInput, DocLabel, FormToggleCheck } from '../form/styles'
import { SubTitle } from '../page/styles'
import { UnitiesTabTable } from '../tables/UnitiesTabTable'
import { TabsPropTypes } from './types'

export const UnityTab: FC<TabsPropTypes> = ({ data, onSuccess, unities }) => {
  const [suggestions, setSuggestions] = useState([])
  const [status, setStatus] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filtered, setFiltered] = useState([])
  const [authorizationUnitCode, setAuthorizationUnitCode] = useState('')
  const [defaultValue, setDefaultValue] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [selectedId, setSelectedId] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [currentTarget, setCurrentTarget] = useState('')
  const [values, setValues] = useState<any>(null)

  const clear = () => {
    setCurrentTarget('clear')
    setStatus(false)
    setAuthorizationUnitCode('')
    setDefaultValue('')
    setIsEdit(false)
    setValues(null)
  }

  const handleGetValue = (value: string) => {
    setCurrentTarget('')
    if (!value) {
      clear()
      return
    }
    if (value.includes('-')) {
      const tempId = parseInt(value.split('-')[0], 10)
      if (tempId) {
        const unit = unities.find((item) => item.seq === tempId)
        if (unit) {
          setValues((cur) => ({
            ...cur,
            unitCode: unit?.id,
            unitName: unit?.name,
            unitSeq: unit?.seq,
          }))
        }
      }
    }
  }

  const handleStatusChange = () => {
    setStatus((cur) => !cur)
  }

  const onSelect = (id: number, unitCode: number) => {
    setCurrentTarget('')
    const unit = unities.find((item) => item.seq === unitCode)
    const selected = data.find((item) => item.id === id)

    setValues({
      ...selected,
      unitCode: unit?.id || 'ND',
      unitName: unit?.name || 'ND',
      unitSeq: unit?.seq,
    })
    setSelectedId(id)
    setIsEdit(true)
    setStatus(selected.unitStatus)
    setAuthorizationUnitCode(selected.authorizationUnitCode)
    setDefaultValue(`${unit.seq} - ${unit.name}`)
  }

  const getPayload = () => ({
    unitStatus: status,
    authorizationUnitCode,
    unitCode: values.unitSeq,
    unitName: values.unitName,
  })

  const handleCreateAuthorizationUnits = async () => {
    try {
      await createAuthorizationUnit(getPayload())

      toast.success('Operação realizada com sucesso!')
      onSuccess()
    } catch (error) {
      console.error(error)
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  const handleUpdateAuthorizationUnit = async () => {
    try {
      await updateAuthorizationUnit(selectedId, getPayload())

      toast.success('Operação realizada com sucesso!')
      onSuccess()
    } catch (error) {
      console.error(error)
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  useEffect(() => {
    const tempSuggestions = []
    unities.forEach((item) => {
      tempSuggestions.push(`${item.seq} - ${item.name}`)
    })

    setSuggestions(tempSuggestions)
    clear()
  }, [data, unities])

  useEffect(() => {
    const formated = searchText.toLowerCase()

    if (!formated) {
      setFiltered(data)
      return
    }

    const temp = data.filter(
      (item) =>
        item.unitCode.toString().includes(formated) ||
        item.unitName.toLowerCase().includes(formated),
    )
    setFiltered(temp)
  }, [searchText])

  useEffect(() => {
    if (authorizationUnitCode && defaultValue && !defaultValue.includes('ND -')) {
      setIsValid(true)
      return
    }

    setIsValid(false)
  }, [authorizationUnitCode, defaultValue, values])

  useEffect(() => {
    if (values) {
      setDefaultValue(`${values.unitSeq} - ${values.unitName}`)
      return
    }
    setDefaultValue('')
  }, [values])

  return (
    <>
      <Row>
        <Col className='d-flex flex-dir-col space-between' xs={4}>
          <DocLabel>Nome ou Código da unidade:</DocLabel>
          <Autocomplete
            defaultValue={defaultValue}
            suggestions={suggestions}
            getValue={handleGetValue}
            placeholder='Digite nome ou código da unidade'
            currentTarget={currentTarget}
            height={25}
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' xs={4}>
          <DocLabel>Código prestador autorização:</DocLabel>
          <DocInput
            onChange={({ target }) => setAuthorizationUnitCode(target.value)}
            value={authorizationUnitCode}
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' xs={1}>
          <DocLabel>Status:</DocLabel>
          <FormToggleCheck onChange={handleStatusChange} checked={status} label='Ativo' />
        </Col>
        <Col
          xs={1}
          className='d-flex flex-dir-col space-between'
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            type='button'
            className='w-100'
            onClick={clear}
            variant='outline-secondary'
            size='sm'
            style={{ height: 25, fontSize: 12, padding: 2 }}
          >
            Cancelar
          </Button>
        </Col>
        <Col
          className='d-flex flex-dir-col space-between'
          style={{ justifyContent: 'flex-end' }}
          xs={2}
        >
          <Button
            style={{ height: 25, fontSize: 12, padding: 2 }}
            type='button'
            size='sm'
            variant='primary'
            className='w-100'
            disabled={!isValid}
            onClick={isEdit ? handleUpdateAuthorizationUnit : handleCreateAuthorizationUnits}
          >
            {isEdit ? 'Atualizar' : 'Adicionar'}
          </Button>
        </Col>
      </Row>
      <hr style={{ marginBottom: 20, marginTop: 20 }} />
      <Row>
        <Col xs={8}>
          <SubTitle>UNIDADES</SubTitle>
        </Col>
        <Col xs={3}>
          <DocInput
            style={{ width: '100%' }}
            onChange={({ target }) => setSearchText(target.value)}
            placeholder='Pesquisar Unidade...'
          />
        </Col>
        <Col>
          <Button
            style={{ height: 25, fontSize: 12, padding: 2 }}
            type='button'
            size='sm'
            variant='primary'
            className='w-100'
          >
            Pesquisar
          </Button>
        </Col>
      </Row>
      <UnitiesTabTable data={filtered && searchText ? filtered : data} onSelect={onSelect} />
    </>
  )
}
