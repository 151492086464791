import { Form, FormSelect } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'
import styled from 'styled-components'

export const FormInput = styled(DebounceInput)<{ disabled: boolean; height?: number }>`
  font-size: 12px;
  color: #4f4f4f;
  border-radius: 5px;
  padding: 6px 10px;
  border: 1px solid #c4c4c4;
  outline: none;
  width: 100%;

  ${({ height }) => height && `height:${height}px;`}
  &:focus {
    border-color: #c4c4c4;
    box-shadow: none;
  }
`
export const FormDropdownButton = styled(FormSelect)`
  font-size: 12px;
  color: #4f4f4f;
  border-color: #c4c4c4;
  border-radius: 5px;
  padding: 6px 10px;
  width: 150px;
  &:focus,
  &:hover,
  &:active {
    border-color: #c4c4c4;
    box-shadow: none;
  }
`

export const DocInput = styled.input`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: transparent;
  font-size: 14px;
  height: 25px;
  padding-left: 4px;
  padding-right: 4px;
  max-width: 100%;
  &:disabled {
    border-color: rgba(196, 196, 196, 0);
  }
  &.borderless {
    border: none;
  }
`

export const DocLabel = styled.label`
  font-weight: bold;
  font-size: 11px;
`

export const DocTextArea = styled.textarea`
  resize: none;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: transparent;
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
  height: 80px;
  width: 100%;
  /* &:disabled {
    border: none;
  } */
`

export const FormToggleCheck = styled(Form.Switch)`
  outline: none;
  .form-check-input {
    &:checked {
      border-color: transparent;
      background-color: #34c759;
    }
    &:focus {
      border-color: rgba(0, 0, 0, 0.25);
      box-shadow: none;
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
    }
  }
`
