import axios from 'axios'
import html2canvas from 'html2canvas'
import $ from 'jquery'
import JSPDF from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Pdf from 'react-to-pdf'
import { toast } from 'react-toastify'
import { Block } from '../../components'
import { CancelTransfusionalModal } from '../../components/cancelTransfusionRequestModal'
import { PageBadge, PageHeader, PageTitle, SubHeader } from '../../components/page/styles'
import CustomPDF from '../../components/transfusionRequest/CustomPDF'
import { NewRequestSection } from '../../components/transfusionRequest/NewRequest'
import { PatientDetailsSection } from '../../components/transfusionRequest/PatientDetails'
import { PdfTransfustionRequest } from '../../components/transfusionRequest/PdfTransfustionRequest'
import {
  cancelTransfutionRequest,
  getTransfutionRequest,
  postUrlBiometric,
  validateBiometric,
  validateRequisition,
} from '../../service'

const PdfOptions = {
  orientation: 'portrait',
  unit: 'cm',
  format: [29, 21],
}

export const TransfusionRequestPage = () => {
  const pdfRef = useRef()
  const { id } = useParams()
  const navigate = useNavigate()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [rTdata, setRtData] = useState(null)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [links, setLinks] = useState<{
    edit: string
    cancel: string
    origin: string
    validate: string
  }>({
    edit: '',
    cancel: '',
    origin: '',
    validate: '',
  })
  const [rtStatus, setRtStatus] = useState<string>('')
  const [isBiometricModalOpen, setIsBiometricModalOpen] = useState(false)
  const [tokenBio, setTokenBio] = useState()
  const [isAuth, setIsAuth] = useState(false)
  const [newHTML, setNewHTML] = useState('')
  const [unityName, setUnityName] = useState('')
  const [isValidated, setIsValidated] = useState(false)
  const [isRequested, setIsRequested] = useState(false)
  const [requestType, setRequestType] = useState<'confirm' | 'cancel' | null>(null)
  const [justification, setJustification] = useState('')

  let toPdfCaller = () => null

  // eslint-disable-next-line react/jsx-props-no-spreading
  const appForwardRef = (props) => <PdfTransfustionRequest {...props} ref={pdfRef} />

  const sendToOrigin = () => {
    toast.info('Você será redirecionado para página de origem em instantes')
    setTimeout(() => {
      window.location.href = links.origin
    }, 3000)
  }

  const printDocument = () => {
    const input1 = document.getElementById('divToPrint1')
    const input2 = document.getElementById('divToPrint2')
    let point = 0

    // Use Promise.all to capture the images of both HTML files
    Promise.all([html2canvas(input1, { scale: 1 }), html2canvas(input2, { scale: 1 })]).then(
      (canvasArray) => {
        const pdf = new JSPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [595.28, 595.28],
        })
        canvasArray.forEach((canvas) => {
          const imgWidth = 555.28 // width of A4 in pts
          const imgHeight = ((canvas.height * imgWidth) / canvas.width) * 1.3
          const positionX = (pdf.internal.pageSize.getWidth() - imgWidth) / 2
          const positionY = 20
          pdf.addImage(
            canvas.toDataURL('image/png'),
            'PNG',
            positionX,
            positionY,
            imgWidth,
            imgHeight,
            null,
          )

          if (point < 1) {
            pdf.addPage()
          }

          point += 1
        })
        pdf.save('Termo de consetimento.pdf')
      },
    )
  }

  const checkRedirection = () => {
    printDocument()

    if (toPdfCaller) {
      toPdfCaller()
    }
    if (!links.origin) {
      navigate('/feedback')
    } else {
      sendToOrigin()
    }
  }

  const doValidateRequisition = async () => {
    try {
      setLoading(true)
      await validateRequisition(id, links.validate)
      checkRedirection()
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const fixData = (data) => {
    let newData = data.replace('paramDedos= ;', "paramDedos= '';")
    newData = newData.replace('<?xml version="1.0" encoding="UTF-8"?>', '')
    newData = newData.replace('href="#"', 'href="javascript:void(0);"')
    newData = newData.replace(/<img src=""/g, '<img')
    newData = newData.replace(
      /<link rel="stylesheet" href="(.*?)"[^>]*/g,
      '<link rel="text/css" href="../../assets/css/biometria.css"',
    )
    newData = newData.replace(
      '<!DOCTYPE composition PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">',
      '',
    )
    newData = newData.replace(
      `<script type="text/javascript" src="${process.env.REACT_APP_HTTP_WSENV}resources/js/capturaTentativas.js"/>`,
      `<script type="text/javascript" src="${process.env.REACT_APP_HTTPS_WSENV}resources/js/capturaTentativas.js"></script>`,
    )
    return newData
  }

  const newUrlBio = (url: string) => url.replace('http', 'https')

  const validateBio = async (contextName, newBase64) => {
    try {
      const { data } = await validateBiometric({ contextName, base64: newBase64, token: tokenBio })
      toast.success(data?.content?.biometria?.mensagemValidacao || 'Operação realizada com sucesso')
      setIsAuth(true)
      if (requestType === 'cancel') {
        setIsCancelModalOpen(true)
      }
    } catch (error) {
      toast.error(error.response.data.content)
    } finally {
      setIsBiometricModalOpen(false)
    }
  }

  const checkBio = () => {
    const interval = setInterval(() => {
      if (isAuth) {
        clearInterval(interval)
        return
      }
      if (sessionStorage.getItem('bio_base64')) {
        validateBio(rTdata.contextName, sessionStorage.getItem('bio_base64'))
        clearInterval(interval)
      }
    }, 1000)
  }

  const injetarHTML = (data) => {
    $('#biometria').append(data)
    $('#confirmar').remove()
    $('#cancelar').remove()
    checkBio()
  }

  const getUrlLegacy = async () => {
    try {
      const { data } = await postUrlBiometric(rTdata.doctorLoginName, rTdata.contextName)
      setTokenBio(data.content.tokenBio)
      const newContent = JSON.parse(data.content.urlValidation)
      const newUrl = newUrlBio(newContent.biometria.url)
      const response = await axios.get(newUrl)
      const newHtml = fixData(response.data)
      setIsBiometricModalOpen(true)
      setNewHTML(newHtml)
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente.')
      setIsBiometricModalOpen(false)
      console.error(error)
    }
    setIsBiometricModalOpen(true)
  }

  const doGetTransfutionRequest = async () => {
    try {
      setLoading(true)

      const { data } = await getTransfutionRequest(id)
      setRtData({
        ...(data?.content?.transfusionRequest || null),
        unityName: data?.content?.unityName,
      })
      setItems(data?.content?.transfusionRequestItems || [])
      setRtStatus(data?.content?.statusRequest || '')
      setUnityName(data?.content?.unityName || '')
      setLinks({
        edit: data?.content?.transfusionRequest?.linkEdit || '',
        cancel: data?.content?.transfusionRequest?.linkCancel || '',
        origin: data?.content?.transfusionRequest?.linkOrigin || '',
        validate: data?.content?.transfusionRequest?.linkValidate || '',
      })
    } catch ({ request, response }) {
      toast.error('Algo deu errado na requisição. Tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const doCancelRequest = async () => {
    try {
      await cancelTransfutionRequest(id, justification, links.cancel)
      toast.success('Cancelamento realizado com sucesso.')
      sendToOrigin()
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  const confirmRequest = () => {
    printDocument()

    getUrlLegacy()
    setRequestType('confirm')
  }

  const confirmCancel = () => {
    getUrlLegacy()
    setRequestType('cancel')
  }

  useEffect(() => {
    doGetTransfutionRequest()
  }, [id])

  useEffect(() => {
    if (isBiometricModalOpen) {
      injetarHTML(newHTML)
    }
  }, [newHTML, isBiometricModalOpen])

  useEffect(() => {
    if (!isBiometricModalOpen) {
      sessionStorage.setItem('bio_base64', '')
      $('#biometria').empty()
    }
  }, [isBiometricModalOpen])

  useEffect(() => {
    setIsValidated(rtStatus.toLowerCase() === 'validado')
    setIsRequested(rtStatus.toLowerCase() === 'solicitado')
  }, [rtStatus])

  useEffect(() => {
    if (isAuth && requestType === 'confirm') {
      doValidateRequisition()
    }
  }, [isAuth, requestType])

  useEffect(() => {
    if (!isCancelModalOpen) {
      setRequestType(null)
    }
  }, [isCancelModalOpen])

  return (
    <>
      <Block>
        <PageHeader>
          <PageTitle>REQUISIÇÃO DE TRANSFUSÃO DE SANGUE</PageTitle>
          {/* <div>
            <Button variant='outline-primary' disabled>
              Nova ficha
            </Button>
            <Button variant='outline-primary' disabled>
              Histórico
            </Button>
          </div> */}
        </PageHeader>
        <SubHeader>
          <div>
            <p className='bold-text s-12'>Hospital:</p>
            <p className='s-14'>{unityName}</p>
          </div>
          <PageBadge>
            Nº RT: #{id} - {rtStatus?.toUpperCase() || ''}
          </PageBadge>
        </SubHeader>
        {loading && !rTdata && 'Carregando registros...'}
        {!loading && rTdata && (
          <>
            <PatientDetailsSection data={rTdata} />
            <NewRequestSection data={rTdata} items={items} />
            <CustomPDF data={rTdata} />
          </>
        )}
        <Row className='h-flex-end'>
          <Col md={1}>
            <Button
              type='button'
              className='w-100'
              onClick={() => navigate(-1)}
              variant='outline-secondary'
              size='sm'
            >
              Voltar
            </Button>
          </Col>
          <Col md={2}>
            <Button
              type='button'
              className={`w-100 ${!isRequested ? 'disabled' : ''}`}
              size='sm'
              variant='outline-danger'
              onClick={confirmCancel}
              disabled={!isRequested}
            >
              Cancelar requisição
            </Button>
          </Col>
          <Col md={2}>
            <Button
              as='a'
              href={links?.edit || ''}
              type='button'
              className={`w-100 ${!isRequested ? 'disabled' : ''}`}
              size='sm'
              variant='outline-primary'
              disabled={!isRequested}
            >
              Editar requisição
            </Button>
          </Col>
          <Pdf targetRef={pdfRef} filename='requisição-transfusão.pdf' options={PdfOptions}>
            {({ toPdf }) => {
              toPdfCaller = toPdf

              return isValidated ? (
                <Col md={2}>
                  <Button
                    as='a'
                    className={`w-100 ${loading ? 'disabled' : ''}`}
                    size='sm'
                    variant='outline-primary'
                    disabled={loading}
                    onClick={checkRedirection}
                  >
                    Reimprimir requisição
                  </Button>
                </Col>
              ) : (
                <Col md={2}>
                  <Button
                    as='a'
                    className='w-100'
                    size='sm'
                    onClick={confirmRequest}
                    disabled={loading || !rTdata}
                  >
                    Confirmar requisição
                  </Button>
                </Col>
              )
            }}
          </Pdf>
        </Row>
      </Block>
      <Modal show={isBiometricModalOpen} onHide={() => setIsBiometricModalOpen(false)}>
        <Modal.Body>
          <div id='biometria' />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            size='sm'
            variant='outline-danger'
            onClick={() => setIsBiometricModalOpen(false)}
          >
            Cancelar biometria
          </Button>
        </Modal.Footer>
      </Modal>
      {rTdata && appForwardRef({ ...rTdata, items })}
      <CancelTransfusionalModal
        show={isCancelModalOpen}
        handleClose={() => setIsCancelModalOpen(false)}
        confirmCancel={doCancelRequest}
        justification={justification}
        setJustification={setJustification}
      />
    </>
  )
}
