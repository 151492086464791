import styled from 'styled-components'

export const BlockStyles = styled.div`
  background-color: white;
  padding: 20px 60px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 14px;
  }

  &.h-100 {
    height: 100%;
  }

  .create-user-page,
  .list-user-page {
    .dropdown,
    .dropdown-toggle {
      height: 25px;
    }
  }
`

export const BlockTitle = styled.h4`
  color: #4f4f4f;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
`
