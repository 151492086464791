import { format } from 'date-fns'
import { FC } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import MOCK_ITEM from '../../mock-request.json'
import { getField } from '../../utils'
import { DocInput, DocLabel, DocTextArea } from '../form/styles'
import { PageSection, SubHeader, SubTitle, SubTitleSmall } from '../page/styles'
import { SubtilesBar, SubtitleLabel } from '../subtitlesBar/styles'
import { TabItemData } from './TabItemData'
import { PatientDetailsSectionEndRequestPropTypes } from './types'

export const NewRequestSection: FC<PatientDetailsSectionEndRequestPropTypes> = ({ data }) => {
  const items = MOCK_ITEM.content.transfusionRequestItems

  return (
    <PageSection>
      <SubHeader>
        <SubTitle>Solicitação</SubTitle>
      </SubHeader>
      <Row className='reset-row'>
        <Col md={6}>
          <DocLabel>Diagnóstico:</DocLabel>
          <DocInput
            type='text'
            value={`${getField('attendanceCodeCid', data)} - ${getField('attendanceCid', data)}`}
            className='w-100'
            disabled
          />
        </Col>
      </Row>
      <SubtilesBar style={{ justifyContent: 'flex-start' }}>
        <SubtitleLabel>Status:</SubtitleLabel>
        <SubtitleLabel className='done'>Autorizado</SubtitleLabel>
        <SubtitleLabel className='late'>Solicitaçao em auditoria</SubtitleLabel>
        <SubtitleLabel className='danger'>Solicitação negada</SubtitleLabel>
        <SubtitleLabel className='stand-by'>Suspenso</SubtitleLabel>
      </SubtilesBar>

      <Tabs defaultActiveKey={items[0].id} className='mt-10 tabs-component'>
        {items.map((item) => (
          <Tab key={item.id} eventKey={item.id} title={item.bloodComponentName}>
            <TabItemData data={item} />
          </Tab>
        ))}
      </Tabs>
      <Row>
        <Col md={6}>
          <SubTitleSmall>Justificativa de cancelamento:</SubTitleSmall>
          <DocTextArea
            as='textarea'
            className='hpx-40'
            placeholder='Descrição da justificativa para o cancelamento da requisição de transfusão de sangue.'
            disabled
          />
        </Col>
        <Col md={6} style={{ display: 'flex', paddingBottom: 8 }}>
          <Row className='d-flex space-between'>
            <Col md={3} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Médico solicitante:</DocLabel>
              <DocInput type='text' disabled value={getField('doctorName', data)} />
            </Col>
            <Col md={2} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>CRM:</DocLabel>
              <DocInput type='text' disabled value={getField('doctorCrmNumber', data)} />
            </Col>
            <Col md={2} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Data:</DocLabel>
              <DocInput
                type='text'
                disabled
                value={format(new Date(data.registrationDate), 'dd/MM/yyyy')}
              />
            </Col>
            <Col md={1} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Hora:</DocLabel>
              <DocInput
                type='text'
                disabled
                value={format(new Date(data.registrationDate), 'HH:mm')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageSection>
  )
}
