import { FC } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { ModalPropTypes } from '../../types'
import { DocInput, DocLabel } from '../form/styles'
import { PageSection, SubHeader, SubTitle } from '../page/styles'
import { CheckingHemocomponentTable } from '../tables/CheckingHemocomponentTable'
import { TimeLine } from '../timeline'
import { TransfusionalModalStyles } from './styles'

const steps = [
  { label: 'Solicitação', timeStamp: '22/04/2022 - 14:20' },
  { label: 'Autorização', timeStamp: '22/04/2022 - 14:20' },
  { label: 'Tipagem', timeStamp: '22/04/2022 - 14:20' },
  { label: 'Bolsa Enviada', timeStamp: '22/04/2022 - 14:20' },
  { label: 'Bolsa Recebida', timeStamp: '22/04/2022 - 14:20' },
  { label: 'Transfusão', timeStamp: '' },
]

export const TransfusionalModal: FC<ModalPropTypes> = ({ handleClose, show }) => (
  <TransfusionalModalStyles show={show} onHide={handleClose}>
    <Modal.Body>
      <PageSection>
        <SubHeader>
          <SubTitle>DADOS DO PACIENTE</SubTitle>
          <SubTitle>N° Atend.: 7854241</SubTitle>
        </SubHeader>
        <Row>
          <Col className='d-flex flex-dir-col space-between' md={3}>
            <DocLabel>Nome do Paciente:</DocLabel>
            <DocInput disabled type='text' value='ROSA OLINDAS DAS CALDAS OLIVEIRA' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={3}>
            <DocLabel>Nome do Mãe:</DocLabel>
            <DocInput disabled type='text' value='Maria de Fátima das Caldas Oliveira' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={1}>
            <DocLabel>Data de Nasc.:</DocLabel>
            <DocInput disabled type='text' value='15/08/1972' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={1}>
            <DocLabel>Idade:</DocLabel>
            <DocInput disabled type='text' value='50 anos' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={1}>
            <DocLabel>Sexo:</DocLabel>
            <DocInput disabled type='text' value='Feminino' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={1}>
            <DocLabel>CPF:</DocLabel>
            <DocInput disabled type='text' value='907.158.460-78' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={1}>
            <DocLabel>RG:</DocLabel>
            <DocInput disabled type='text' value='41.547.651-3' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={1}>
            <DocLabel>Peso:</DocLabel>
            <DocInput disabled type='number' value='15/08/1972' />
          </Col>
        </Row>
        <Row>
          <Col className='d-flex flex-dir-col space-between' md={4}>
            <DocLabel>Unidade:</DocLabel>
            <DocInput disabled type='text' value='Hospital Antonio Prudente' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={2}>
            <DocLabel>Clínica:</DocLabel>
            <DocInput disabled type='text' value='Posto de Atendimento 1111 - POSTO P1A' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={2}>
            <DocLabel>Enfermaria/APT°:</DocLabel>
            <DocInput disabled type='text' value='Enfermaria' />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={1}>
            <DocLabel>Leito:</DocLabel>
            <DocInput disabled type='text' value='205' />
          </Col>
        </Row>
      </PageSection>
      <PageSection>
        <TimeLine steps={steps} />
      </PageSection>
      <PageSection>
        <CheckingHemocomponentTable
          headers={[
            'Hemocomponente solicitado',
            'N° da Bolsa.',
            'ABO/Rh',
            'Volume',
            'Prof. Resp.',
            'Data Admin.',
            'Hora',
          ]}
          data={['test', '1', '2', '3', '4', '5', '6']}
        />
      </PageSection>
    </Modal.Body>
    <Modal.Footer>
      <Button size='sm' variant='outline-secondary' onClick={handleClose}>
        Fechar
      </Button>
      <Button size='sm' variant='primary' onClick={handleClose}>
        Imprimir
      </Button>
    </Modal.Footer>
  </TransfusionalModalStyles>
)
