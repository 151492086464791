import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LoginModal } from '..'
import { ReactComponent as LogoffIcon } from '../../assets/icons/log-off.svg'
import { ReactComponent as HapvidaLogo } from '../../assets/icons/logo.svg'
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { useAuthContext } from '../../context/auth'
import {
  HeaderBlock,
  HeaderBtn,
  HeaderStyles,
  MenuButton,
  MenuWrapper,
  TitleStyles,
  UserLabelStyles,
} from './styles'
import { HeaderPropTypes } from './types'

export const Header: FC<HeaderPropTypes> = ({ toggleMenu, samToken }) => {
  const { clearAuth, token, acessos, tokenInfo } = useAuthContext()

  const location = useLocation()

  const [show, setShow] = useState(true)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleLogout = () => {
    clearAuth()
  }

  const getName = () => acessos?.operador?.nome || ''

  const renderHeaderBtn = () =>
    !acessos ? (
      <HeaderBtn onClick={handleShow} type='button'>
        <UserIcon />
      </HeaderBtn>
    ) : (
      <HeaderBtn onClick={handleLogout} type='button'>
        <LogoffIcon />
      </HeaderBtn>
    )

  useEffect(() => {
    if (token || samToken || tokenInfo) {
      setShow(false)
      return
    }
    setShow(true)
  }, [location, token, localStorage, samToken, tokenInfo, acessos])

  return (
    <>
      <HeaderStyles>
        <MenuWrapper>
          <MenuButton type='button' onClick={toggleMenu}>
            <MenuIcon />
          </MenuButton>
          <HapvidaLogo />
          <TitleStyles>Sistema de Gestão de banco de sangue</TitleStyles>
        </MenuWrapper>

        {location.pathname.includes('requisicao-transfusao') ? null : (
          <HeaderBlock>
            <UserLabelStyles>{acessos ? `Olá, ${getName()}` : 'Fazer Login'}</UserLabelStyles>
            {renderHeaderBtn()}
          </HeaderBlock>
        )}
      </HeaderStyles>
      {show && <LoginModal show={show} handleClose={handleClose} />}
    </>
  )
}
