import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getField } from '../../utils'
import { CustomCheckbox } from '../customCheckbox'
import { DocTextArea } from '../form/styles'
import { SubTitleSmall } from '../page/styles'

export const TabItemData: FC<{ data: any }> = ({ data }) => (
  <Row className='reset-row tab-row'>
    <Col md={6}>
      <Row>
        <Row>
          <Col md={12}>
            <SubTitleSmall>Concentrado de Hemácias</SubTitleSmall>
          </Col>
          <Col md={4}>
            <SubTitleSmall>Qtd: {`${getField('qtyDosage', data)}`}</SubTitleSmall>
          </Col>
          <Col md={8}>
            <SubTitleSmall>
              Unid. Med.:{' '}
              {`${getField('codeUniDosageMed', data)}${getField(
                'codeUnityUsual',
                data,
              ).toLowerCase()}`}
            </SubTitleSmall>
          </Col>
        </Row>
        <Col md={12}>
          <SubTitleSmall>Procedimentos Especiais:</SubTitleSmall>
        </Col>
        <Row>
          <Col md={3}>
            <CustomCheckbox
              label='Fenotipado'
              disabled
              checked={getField('specialProced', data).toLowerCase().includes('fenotipado')}
            />
          </Col>
          <Col md={3}>
            <CustomCheckbox
              label='Irradiado'
              disabled
              checked={getField('specialProced', data).toLowerCase().includes('irradiado')}
            />
          </Col>
          <Col md={3}>
            <CustomCheckbox
              label='Desleucocitado'
              disabled
              checked={getField('specialProced', data).toLowerCase().includes('desleucocitado')}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <CustomCheckbox
              label='Lavado'
              disabled
              checked={getField('specialProced', data).toLowerCase().includes('lavado')}
            />
          </Col>
          <Col md={3}>
            <CustomCheckbox
              label='Filtrado'
              disabled
              checked={getField('specialProced', data).toLowerCase().includes('filtrado')}
            />
          </Col>
          <Col md={3}>
            <CustomCheckbox
              label='Aférese'
              disabled
              checked={getField('specialProced', data).toLowerCase().includes('aferese')}
            />
          </Col>
        </Row>
      </Row>
      <SubTitleSmall>Justificativa:</SubTitleSmall>
      <DocTextArea
        as='textarea'
        placeholder=''
        value={getField('specialProcedJustDescription', data)}
        disabled
        name='specialProcedJustDescription'
      />
      <SubTitleSmall>Informações adicionais:</SubTitleSmall>
      <DocTextArea
        as='textarea'
        placeholder=''
        value={getField('drugInformation', data)}
        disabled
        name='drugInformation'
      />
    </Col>
    <Col md={6}>
      <SubTitleSmall>Indicação da transfusão:</SubTitleSmall>
      <DocTextArea
        as='textarea'
        placeholder=''
        value={getField('useIndicationDescription', data).split(',').join(', ')}
        disabled
        name='useIndicationDescription'
      />
      <SubTitleSmall>Contraindicações:</SubTitleSmall>
      <DocTextArea
        as='textarea'
        className='hpx-60'
        placeholder=''
        value={getField('useContraIndicationDescription', data).split(',').join('. ')}
        disabled
        name='useContraIndicationDescription'
      />
      <SubTitleSmall>Justificativa da contraindicação:</SubTitleSmall>
      <DocTextArea
        as='textarea'
        className='hpx-60'
        placeholder=''
        disabled
        value={getField('justContraIndicationDescription', data)}
        name='justContraIndicationDescription'
      />
    </Col>
  </Row>
)
