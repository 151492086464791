import axios from 'axios'
import { getUrlData } from '../utils'
import {
  AutorizationUnitsPayloadTypes,
  CodeProceedingAuthorizationPayloadTypes,
  FilterTypes,
  LoginTypes,
} from './types'
/* eslint-disable camelcase */

const getRequestAuth = () => ({
  sgbs_token: sessionStorage.getItem('sgbs_token') || '',
  sgbs_appkey: sessionStorage.getItem('sgbs_appkey') || '',
})

export const service = (appKey?: string, token?: string) =>
  axios.create({
    baseURL:
      process.env.REACT_APP_BFF_API ||
      'https://apimgmt-dev-001.azure-api.net/assistance/blood-bank/web/api/v1/',
    headers: {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      Authorization:
        sessionStorage.getItem('SAMtoken') ||
        getUrlData().token ||
        `Bearer ${token || getRequestAuth().sgbs_token}`,
      originAuth: getUrlData().Origin,
      'X-Hap-Application-Key': appKey || getRequestAuth().sgbs_appkey,
    },
  })

export const serviceLegacy = () =>
  axios.create({
    baseURL:
      process.env.REACT_APP_LEGACY_API ||
      'https://apimgmt-dev-001.azure-api.net/assistance/blood-bank/identity/api/v1/',
    headers: {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_LEGACY,
    },
  })

service().interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
)

export const getTransfutionRequest = async (code: string | any) =>
  service().get(`transfusion-requests/${code}`)

export const getTransfusionRequestTyping = async (id: string | any) =>
  service().post(`transfusion-requests/typing`, { transfusionRequestId: id })

export const cancelTransfutionRequest = async (id: string, justify: string, linkCancel: string) =>
  service().put(`transfusion-requests/${id}/cancelation`, { justify, linkCancel })

export const postUrlBiometric = async (doctorLoginName: string | any, contextName: string | any) =>
  serviceLegacy().post('/authentication/biometry/url', {
    doctorLoginName,
    contextName,
  })

export const validateBiometric = async ({ contextName, base64, token }) =>
  serviceLegacy().post(
    '/authentication/biometry/validation',
    {
      contextName,
      base64,
    },
    {
      headers: {
        token,
      },
    },
  )

export const validateRequisition = async (id: string, linkValidate) =>
  service().post(`transfusion-requests/${id}/validation`, { linkValidate })

export const login = async ({ codigoPerfil, ...rest }: LoginTypes) =>
  serviceLegacy().post(
    '/authentication/login',
    {
      ...rest,
      trocarSenha: false,
      codigoPerfil: parseInt(codigoPerfil, 10),
    },
    {
      headers: {
        'X-Hap-Application-Key': getRequestAuth().sgbs_appkey,
      },
    },
  )

export const validateToken = async (token = '') =>
  serviceLegacy().post(
    '/authentication/token/validation',
    {},
    {
      headers: {
        Authorization: `Bearer ${token || getRequestAuth().sgbs_token}`,
        'X-Hap-Application-Key': getRequestAuth().sgbs_appkey,
      },
    },
  )

export const getDashboardData = async (
  {
    attendanceCode,
    attendanceType,
    initialDate,
    finalDate,
    patientName,
    position,
    unityId,
    status,
  }: FilterTypes,
  appKey = '',
  token = '',
) =>
  service(appKey, token).get(
    `dashboard?attendanceCode=${attendanceCode}&patientName=${patientName}&initialDate=${initialDate}&
finalDate=${finalDate}&
unityId=${unityId}&attendanceType=${attendanceType}&status=${status}&position=${position}`,
  )

export const getAllProfiles = async () => service().get('profile-hapvida')

export const getAllCustomProfiles = async () => service().get('profile')

export const createProfile = async (
  description: string,
  statusFlag: boolean,
  profilesHap: { profileCode: number; profileName: string }[],
) =>
  service().post('profile', {
    profile: {
      description,
      statusFlag,
    },
    profileHapvida: profilesHap,
  })

export const getProfile = async (id: string) => service().get(`profile/${id}`)

export const updateProfile = async (
  id: string,
  description: string,
  statusFlag: boolean,
  profilesHap: { profileCode: number; profileName: string }[],
) =>
  service().put(`profile/${id}`, {
    profile: {
      description,
      statusFlag,
    },
    profileHapvida: profilesHap,
  })

export const getAuthorizationUnitsData = async () => service().get('authorization-unit')

export const getAuthorizationUnits = async () => service().get('authorization-unit/unitys')

export const createAuthorizationUnit = async (payload: AutorizationUnitsPayloadTypes) =>
  service().post('authorization-unit', {
    ...payload,
  })

export const updateAuthorizationUnit = async (id: number, payload: AutorizationUnitsPayloadTypes) =>
  service().put(`authorization-unit/${id}`, {
    ...payload,
  })

export const getCodeProceedingsAuthorization = async () =>
  service().get('code-proceedings-authorization')

export const createCodeProceedingAuthorization = async (
  payload: CodeProceedingAuthorizationPayloadTypes,
) => service().post('code-proceedings-authorization', { ...payload })

export const updateCodeProceedingAuthorization = async (
  id: number,
  payload: CodeProceedingAuthorizationPayloadTypes,
) => service().put(`code-proceedings-authorization/${id}`, { ...payload })
