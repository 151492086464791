import { format } from 'date-fns'

export const formatNumber = (text: string) => (text ? parseFloat(text).toLocaleString('pt-BR') : '')

export const getDateModalityTransf = (data, callback) =>
  callback('modalityTransfName', data).toLowerCase().includes('programada/reserva') &&
  data?.dateModalityTransf
    ? format(new Date(data.dateModalityTransf), 'dd/MM/yyyy HH:mm')
    : format(new Date(data.registrationDate), 'dd/MM/yyyy HH:mm')

export const getUrlData = (): { token: string; Origin: string } => {
  const values = window.location.search.split('?')
  let urlData: { token: string; Origin: string } = { token: '', Origin: '' }

  values.forEach((item) => {
    urlData = { ...urlData, [item.split('=')[0]]: item.split('=')[1] }
  })

  return urlData
}

export const getField = (name, data) => data[name] || ''
