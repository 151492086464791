export const DASHBOARD_TABLE_HEADERS = [
  { label: 'Solic.', value: 'statusRequest' },
  { label: 'Tipagem', value: 'typing' },
  { label: 'N° RT', value: 'id' },
  { label: 'Nº Atend.', value: 'attendanceCode' },
  { label: 'Paciente', value: 'patientName' },
  { label: 'Tipo de Atend.', value: 'attendanceType' },
  { label: 'Modalidade Transf.', value: 'modalityTransfName' },
  { label: 'Unidade', value: 'unityName' },
  { label: 'Posto', value: 'station' },
  { label: 'Ações', value: 'actions' },
]

export const INITIAL_VALUES = {
  attendanceCode: '',
  patientName: '',
  unityId: '001',
  attendanceType: 'todos',
  status: 'todos',
  position: '0',
  initialDate: '',
  finalDate: '',
}

export const TIPO_ATENDIMENTO = [
  { value: 'todos', label: 'Todos' },
  { value: 'CONSULTA CLINICA', label: 'Consulta clínica' },
  { value: 'INTERNACAO', label: 'Internação' },
]

export const STATUS = [
  { value: 'todos', label: 'Todos' },
  { value: 'cancelado', label: 'Cancelado' },
  { value: 'pendente', label: 'Pendente' },
  { value: 'concluido', label: 'Concluído' },
]

export const PRAZO = [
  { value: '0', label: 'Prazo' },
  { value: '1', label: 'Dentro do prazo' },
  { value: '2', label: 'Fora do prazo' },
]
