/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { format } from 'date-fns'
import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Autocomplete,
  Block,
  LoginModal,
  TransfusionRequestTable,
  TransfusionalModal,
  TypingStampModal,
} from '../../components'
import { CustomDatePicker, CustomDatePickerInput } from '../../components/datePicker/styles'
import { DocLabel, FormDropdownButton, FormInput } from '../../components/form/styles'
import { useAuthContext } from '../../context/auth'
import { getDashboardData } from '../../service'
import { PatientData } from '../../types'
import { INITIAL_VALUES, STATUS, TIPO_ATENDIMENTO } from './utils'

export const Home = () => {
  const navigate = useNavigate()
  const { token, appKey, acessos, suggestions } = useAuthContext()

  const [data, setData] = useState(INITIAL_VALUES)
  const [datesRange, setDatesRange] = useState<[Date | null, Date | null]>([
    new Date(new Date().setDate(new Date().getDate() - 2)),
    new Date(),
  ])
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false)
  const [rts, setRts] = useState([])
  const [pages, setPages] = useState<number[]>([])
  const [isValid, setIsValid] = useState(false)
  const [unityId, setUnityId] = useState('')
  const [currentPage, setCurrentPage] = useState('0')
  const [selectedRow, setSelectedRow] = useState<PatientData>()

  const [startDate, endDate] = datesRange

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>, element: string) => {
    setData((cur) => ({ ...cur, [element]: target.value.trim() }))
  }

  const handleCallbackLogin = (callbackData: any) => {
    const name = callbackData.acessos[0].operador.nome

    setSelectedRow((row) => ({ ...row, collectorName: name }))
    setIsLoginModalOpen(false)
    setIsTicketModalOpen(true)
  }

  const validateAction = (item) => {

    const isValidatedOrAuthorized = item.statusRequestDto.find(
      (obj) => obj.statusDescription.toLowerCase() === 'validado',
    )

    const isTyped = item.statusRequestDto.find(
      (obj) => obj.statusDescription.toLowerCase() === 'tipado',
    )

    if (isTyped) {
      toast.info(
        "Rt já tipada !",
      )

      return false

    }

    if (!isValidatedOrAuthorized) {
      toast.info(
        "A tipagem sanguínea só poderá ser realizada se a RT's estiver com status Validada ou Autorizada.",
      )

      return false
    }

    return true
  }

  const handleDropDownClick = (action: string, item: PatientData) => {
    if (action === 'rt') {
      navigate(`/requisicao-transfusao/${item?.transfusionRequest?.id || ''}`)
      return
    }

    if (action === 'etiq-tipagem') {

      const isValidated = validateAction(item)

      if (isValidated) {
        setSelectedRow(item)
        setIsLoginModalOpen(true)
      }

      return
    }

    if (action === 'acomp-transf') {
      setIsModalOpen(true)
    }
  }

  const renderPages = (total: number) => {
    const temp = []
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < total; index++) {
      temp.push(index)
    }
    setPages(temp)
  }

  const getPayload = (reset, page = '0') => {
    const temp = datesRange.map((item) => format(item || new Date(), 'yyyy-MM-dd'))

    const payload = {
      ...data,
      initialDate: temp[0],
      finalDate: temp[1],
      position: page,
      unityId,
    }

    if (reset) payload.position = '0'

    sessionStorage.setItem('dashboard_filter', JSON.stringify(payload))

    return payload
  }

  const handleSearch = async (payload, page = '0') => {
    try {
      setLoading(true)
      const { data: responseData } = await getDashboardData(
        { ...payload, position: page },
        appKey,
        token,
      )

      setLoading(false)
      if (typeof responseData.content === 'string') {
        toast.info("Nâo existem RT's para esse filtro")
      }

      setData((cur) => ({ ...cur, position: page }))
      setRts(responseData?.content?.transfusionRequests || [])
      renderPages((responseData?.content?.quantityPages || 0) + 1)
      setCurrentPage(page)
    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')
      setLoading(false)
    }
  }

  const renderOptions = (list: { value: string; label: string }[], selected) =>
    list.map(({ value, label }) => (
      <option key={value} value={value} selected={value === selected} disabled={value === '0'}>
        {label}
      </option>
    ))

  const handleGetValue = (value: string) => {
    if (value.includes(' - ')) {
      setUnityId(value.split(' - ')[0] || '')
      return
    }
    setUnityId('')
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <CustomDatePickerInput type='button' onClick={onClick} ref={ref}>
      {value}
    </CustomDatePickerInput>
  ))

  const handleCloseStampingModal = () => {
    try {
      setIsTicketModalOpen(false)
      handleSearch(getPayload(true))
    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')
    }
  }

  const getDefaultUnity = (): string =>
    suggestions.find((item) => item.includes(unityId || '')) || ''

  const getPreviewedFilter = (origin) => {
    setDatesRange([new Date(origin.initialDate), new Date(origin.finalDate)])
    setCurrentPage(origin.position)
    setUnityId(origin?.unityId || acessos?.filial?.codigo || '')
    setData(origin)
  }

  useEffect(() => {
    if (appKey && token) {
      setTimeout(() => {
        const dashboardFilter = JSON.parse(sessionStorage.getItem('dashboard_filter'))
        if (dashboardFilter) {
          getPreviewedFilter(dashboardFilter)
          handleSearch(dashboardFilter)
          return
        }

        const temp = datesRange.map((item) => format(item || new Date(), 'yyyy-MM-dd'))
        handleSearch({
          ...data,
          initialDate: data.initialDate || temp[0],
          finalDate: data.finalDate || temp[1],
        })
      }, 500)
    }
  }, [appKey, token])

  useEffect(() => {
    if (!unityId || !datesRange[0] || !datesRange[1]) {
      setIsValid(false)
      return
    }

    setIsValid(true)
  }, [unityId, datesRange])

  return (
    <>
      <Block title='DASHBOARD'>
        <Container fluid>
          <Row className='no-padding-x'>
            <Col>
              <DocLabel>Nº Atendimento</DocLabel>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'attendanceCode')
                }
                placeholder='Nº Atendimento'
                debounceTimeout={500}
                disabled={false}
                value={data.attendanceCode}
              />
            </Col>
            <Col md={3}>
              <DocLabel>Nome do Paciente</DocLabel>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'patientName')
                }
                placeholder='Nome do Paciente'
                debounceTimeout={500}
                disabled={false}
                value={data.patientName}
              />
            </Col>
            <Col xs={2}>
              <DocLabel>Período *</DocLabel>
              <CustomDatePicker
                placeholderText='Período'
                selectsRange
                startDate={startDate}
                endDate={endDate}
                id='datesRange'
                dateFormat='dd/MM/yyyy'
                onChange={(update: [Date | null, Date | null]) => {
                  setDatesRange(update)
                }}
                customInput={<ExampleCustomInput />}
              />
            </Col>
            <Col>
              <DocLabel>Unidade *</DocLabel>
              <Autocomplete
                defaultValue={getDefaultUnity()}
                suggestions={suggestions}
                getValue={handleGetValue}
                placeholder='Unidade'
                currentTarget=''
              />
            </Col>
            <Col>
              <DocLabel>Tipo de atendimento *</DocLabel>
              <FormDropdownButton
                id='tipoAtendimento'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'attendanceType')
                }
                title='Tipo de atendimento'
                defaultValue='todos'
              >
                {renderOptions(TIPO_ATENDIMENTO, data.attendanceType)}
              </FormDropdownButton>
            </Col>
            <Col>
              <DocLabel>Status *</DocLabel>
              <FormDropdownButton
                id='status'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'status')}
                title='Status'
                defaultValue='todos'
              >
                {renderOptions(STATUS, data.status)}
              </FormDropdownButton>
            </Col>
            {/* <Col md={2}>
              <FormDropdownButton
                id='prazo'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'position')}
                title='Prazo'
                defaultValue='0'
              >
                {PRAZO.map(({ value, label }) => (
                  <option key={value} value={value} disabled={!parseInt(value, 10)}>
                    {label}
                  </option>
                ))}
              </FormDropdownButton>
            </Col> */}
            <Col style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button
                style={{ width: '100%', height: 32, padding: 0 }}
                variant='primary'
                type='button'
                onClick={() => handleSearch(getPayload(true))}
                disabled={loading || !isValid}
              >
                Pesquisar
              </Button>
            </Col>
            <Col xs={12}>
              <span
                style={{ display: 'inline-block', marginTop: 8, marginLeft: '-12px', fontSize: 12 }}
              >
                * campos obrigatórios
              </span>
            </Col>
          </Row>
          <Row className='table-row'>
            {loading ? (
              'Buscando...'
            ) : (
              <TransfusionRequestTable
                data={rts}
                onSelect={(action, item) =>
                  handleDropDownClick(action, item as unknown as PatientData)
                }
                pages={pages}
                setPage={(val) => {
                  handleSearch(getPayload(true), val)
                }}
                currentPage={currentPage}
              />
            )}
          </Row>
        </Container>
      </Block>
      {/* <Container fluid>
        <Row className='no-padding-x'>
          <Col className='col' md={8}>
            <TransfusionProcessPanel />
          </Col>
          <Col md={4}>
            <TransfusionProcessTime />
          </Col>
        </Row>
      </Container> */}
      <LoginModal
        layoutVariant='stamp-login'
        show={isLoginModalOpen}
        callbackLogin={handleCallbackLogin}
        handleClose={() => setIsLoginModalOpen(false)}
      />
      <TransfusionalModal show={isModalOpen} handleClose={() => setIsModalOpen(false)} />
      {isTicketModalOpen && (
        <TypingStampModal
          patientData={selectedRow}
          show={isTicketModalOpen}
          handleClose={() => handleCloseStampingModal()}
        />
      )}
    </>
  )
}
