/* eslint-disable react/jsx-no-useless-fragment */
import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as LogoHapvidaIcon } from '../../assets/icons/logo-hapvida.svg'
import { useAuthContext } from '../../context/auth'
import { login, validateToken } from '../../service'
import { AppSelect } from '../appSelect'
import { DocInput, DocLabel } from '../form/styles'
import { PageTitle, SubTitle } from '../page/styles'
import { CloseButton, CloseIcon, LoginModalStyles } from './styles'
import { LoginModalPropTypes, LoginTypes } from './types'

export const LoginModal = ({
  show,
  callbackLogin,
  handleClose,
  layoutVariant = 'login',
}: LoginModalPropTypes) => {
  const { setAuth, setAppKey, setTokenInfo } = useAuthContext()

  const navigate = useNavigate()

  const [payload, setPayload] = useState<LoginTypes>({
    codigoPerfil: undefined,
    host: 'hap',
    senha: '',
    usuario: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPayload((cur) => ({ ...cur, [target.name]: target.value }))
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const { data: loginData } = await login(payload)
      const loginDataContent = JSON.parse(loginData.content)
      const { data: validationData } = await validateToken(loginDataContent?.token)
      const validationDataContent = JSON.parse(validationData.content)

      setAuth(loginDataContent)
      setTokenInfo(validationDataContent)
      toast.success('Login realizado com sucesso!')
      setIsLoading(false)
      navigate('/')
    } catch ({ response }) {
      if (response?.data?.content) {
        const msg = JSON.parse(response?.data?.content)
        toast.error(msg.mensagem)
      } else {
        toast.error('Falhar na operação!')
      }
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitStampLogin = async () => {
    try {
      setIsLoading(true)
      const { data: loginData } = await login(payload)
      const loginDataContent = JSON.parse(loginData.content)
      const { data: validationData } = await validateToken(loginDataContent?.token)
      const validationDataContent = JSON.parse(validationData.content)

      toast.success('Login realizado com sucesso!')
      setIsLoading(false)
      callbackLogin(validationDataContent)
      setPayload({
        codigoPerfil: 3,
        host: 'hap',
        senha: undefined,
        usuario: undefined,
      })
    } catch ({ response }) {
      if (response?.data?.content) {
        const msg = JSON.parse(response?.data?.content)
        toast.error(msg.mensagem)
      } else {
        toast.error('Falha na operação!')
      }
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseFlow = () => {
    setPayload({
      codigoPerfil: 3,
      host: 'hap',
      senha: '',
      usuario: '',
    })

    handleClose()
  }

  useEffect(() => {
    setPayload((pay) => ({
      ...pay,
      codigoPerfil: 3,
      host: 'hap',
    }))
  }, [])

  useEffect(() => {
    let tempCount = 0
    Object.values(payload)?.forEach((item) => {
      if (item) {
        tempCount += 1
      }
    })

    if (payload?.host) {
      setAppKey(`${payload?.host}-BSA005`)
    }

    setIsValid(tempCount === 4)
  }, [payload])

  return (
    <LoginModalStyles layoutVariant={layoutVariant} show={show}>
      {layoutVariant === 'stamp-login' && (
        <Modal.Header>
          <div className='d-flex justify-content-end w-100'>
            <CloseButton variant='light' onClick={handleCloseFlow}>
              <CloseIcon />
            </CloseButton>
          </div>
        </Modal.Header>
      )}
      <Modal.Body>
        {layoutVariant === 'stamp-login' ? (
          <div className='logo-wrapper'>
            <SubTitle style={{ color: '#4F4F4F', fontWeight: 400, fontSize: 12 }}>
              Usuário responsável pela coleta
            </SubTitle>
            <PageTitle
              style={{ textTransform: 'uppercase', textAlign: 'center', color: '#4F4F4F' }}
            >
              Identifique-se
            </PageTitle>
          </div>
        ) : (
          <div className='logo-wrapper'>
            <LogoHapvidaIcon />
            <PageTitle style={{ textTransform: 'uppercase', textAlign: 'center', fontSize: 12 }}>
              Sistema de Gestão de banco de sangue
            </PageTitle>
          </div>
        )}

        {layoutVariant === 'stamp-login' ? (
          <></>
        ) : (
          <>
            <div>
              <Form.Check
                inline
                label='Demais filiais'
                name='host'
                type='radio'
                id='inline-radio-2'
                className='fs-13'
                value='hap'
                checked={payload.host === 'hap'}
                onChange={handleChange}
              />
              <Form.Check
                inline
                label='Unidade Recife'
                name='host'
                type='radio'
                id='inline-radio-1'
                className='fs-13'
                value='schosp'
                checked={payload.host === 'schosp'}
                onChange={handleChange}
              />
            </div>
            <hr />
            <DocLabel>Código do Perfil:</DocLabel>
            <AppSelect
              options={[
                { label: 'Selecione perfil', value: '0', disabled: true },
                { label: 'Administração', value: '7' },
                { label: 'Banco de sangue', value: '1' },
                { label: 'Enfermagem', value: '2' },
                { label: 'Médico', value: '3' },
                { label: 'Técnico em Enfermagem', value: '6' },
              ]}
              name='codigoPerfil'
              value={payload.codigoPerfil}
              onChange={handleChange}
            />
          </>
        )}

        <DocLabel htmlFor='login'>Nome de usuário:</DocLabel>
        <DocInput
          type='text'
          name='usuario'
          className='modal-input text'
          placeholder='Login'
          onChange={handleChange}
        />
        <div className='modal-input-wrapper'>
          <DocLabel htmlFor='password'>Senha:</DocLabel>
          <DocInput
            type={showPassword ? 'text' : 'password'}
            name='senha'
            className='modal-input password'
            placeholder='Senha'
            onChange={handleChange}
          />
          <button
            type='button'
            className='modal-input-btn'
            onClick={() => setShowPassword((cur) => !cur)}
          >
            <EyeIcon />
          </button>
        </div>
        {layoutVariant !== 'stamp-login' && (
          <Row>
            {/* <Col>
            <Form.Check
              inline
              label='Lembrar'
              name='remember-me'
              type='checkbox'
              id='inline-radio-1'
              className='fs-13'
            />
          </Col> */}
            <Col style={{ textAlign: 'right', marginBottom: 20 }}>
              <a
                target='_blank'
                href='https://portaldeservicos.hapvida.com.br'
                className='recover-pw'
                rel='noreferrer'
              >
                Esqueceu a senha?
              </a>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          disabled={isLoading || !isValid}
          className='login-btn'
          onClick={layoutVariant === 'stamp-login' ? handleSubmitStampLogin : handleSubmit}
        >
          {isLoading && <Spinner animation='border' size='sm' variant='light' />}
          {!isLoading && layoutVariant !== 'stamp-login' && 'Entrar'}
          {!isLoading && layoutVariant === 'stamp-login' && 'Efetuar login'}
        </Button>
      </Modal.Footer>
    </LoginModalStyles>
  )
}
