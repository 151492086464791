import styled from 'styled-components'

export const CustomCheckboxStyles = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0px;
  padding-left: 18px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #eee;
    border-radius: 2px;
    margin-top: 3px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 3px;
      top: 3px;
      height: 8px;
      width: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: #0d6efd;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`
