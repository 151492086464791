import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ReactComponent as XIcon } from '../../assets/icons/x.svg'
import { AppSelect, Block } from '../../components'
import { AppBadge, AppBadgeWrapper } from '../../components/appBadge/styles'
import { DocInput, DocLabel } from '../../components/form/styles'
import { PageHeader, PageSection, PageTitle } from '../../components/page/styles'
import { ProfilesTable } from '../../components/tables/ProfilesTable'
import { useAuthContext } from '../../context/auth'
import {
  createProfile,
  getAllCustomProfiles,
  getAllProfiles,
  getProfile,
  updateProfile,
} from '../../service'

export const CreateListProfile = () => {
  const { appKey, token } = useAuthContext()

  const [profiles, setProfiles] = useState([])
  const [customProfiles, setCustomProfiles] = useState([])
  const [filtered, setFiltered] = useState([])
  const [addedProfiles, setAddedProfiles] = useState<{ label: string; value: number }[]>([])
  const [profileStatus, setProfileStatus] = useState<boolean>(false)
  const [profileName, setProfileName] = useState('')
  const [searchText, setSearchText] = useState('')
  const [editProfileId, setEditProfileId] = useState('')

  const handleProfileStatusChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setProfileStatus(Boolean(parseInt(target.value, 10)))
  }

  const addProfile = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      const temp = profiles.find((item) => item.value === parseInt(target.value, 10))
      const isAdded = addedProfiles.filter((item) => item.value === parseInt(target.value, 10))

      if (!isAdded.length) {
        setAddedProfiles((cur) => [...cur, temp])
      }
    }
  }

  const removeProfile = (value) => {
    const temp = addedProfiles.filter((item) => item.value !== value)
    setAddedProfiles(temp)
  }

  const getProfiles = async () => {
    try {
      const { data } = await getAllProfiles()

      const tempProfiles = data?.content
        .map((item) => ({
          ...item,
          value: item?.id,
          label: item?.profileName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      setProfiles([{ value: 0, label: 'Selecione' }, ...tempProfiles])
    } catch (error) {
      console.error(error)
    }
  }

  const getCustomProfiles = async () => {
    try {
      const { data } = await getAllCustomProfiles()
      const temp = []
      data.content.map((item) => {
        temp.push({
          id: item.profile.id.toString(),
          description: item.profile.description,
          statusFlag: item.profile.statusFlag,
        })
        return item
      })
      setCustomProfiles(temp)
      setFiltered([])
      toast.success('Lista atualizada com sucesso!')
    } catch (error) {
      console.error(error)
    }
  }

  const cancelEdit = () => {
    setEditProfileId('')
    setAddedProfiles([])
    setProfileName('')
    setProfileStatus(true)
  }

  const handleSubmit = async () => {
    try {
      const tempAdded = addedProfiles.map((item) => ({
        profileCode: item.value,
        profileName: item.label,
      }))
      await createProfile(profileName, profileStatus, tempAdded)

      toast.success('Perfil criado com sucesso!')

      getCustomProfiles()
    } catch (error) {
      console.error(error?.response || '')
    } finally {
      cancelEdit()
    }
  }

  const handleGetProfile = async (id: string) => {
    try {
      setEditProfileId(id)
      const { data } = await getProfile(id)
      const temp = []

      data?.content?.profileHapvida.map((item) => {
        temp.push({
          value: item.id,
          label: item.profileName,
        })
        return item
      })
      setProfileName(data?.content?.profile?.description || '')
      setProfileStatus(data?.content?.profile?.statusFlag)
      setAddedProfiles(temp)
    } catch (error) {
      console.error(error?.response || '')
    }
  }

  const handleEditProfile = async () => {
    try {
      const tempAdded = addedProfiles.map((item) => ({
        profileCode: item.value,
        profileName: item.label,
      }))
      await updateProfile(editProfileId, profileName, profileStatus, tempAdded)

      toast.success('Perfil atualizado com sucesso!')

      getCustomProfiles()
    } catch (error) {
      console.error(error?.response || '')
    } finally {
      cancelEdit()
    }
  }

  const loadProfiles = () => {
    cancelEdit()
    getCustomProfiles()
    getProfiles()
  }

  useEffect(() => {
    if (appKey && token) {
      loadProfiles()
    }
  }, [appKey, token])

  useEffect(() => {
    const formated = searchText.toLowerCase()

    const temp = customProfiles.filter(
      (item) => item.id.includes(formated) || item.description.toLowerCase().includes(formated),
    )
    setFiltered(temp)
  }, [searchText])

  return (
    <Block className='create-user-page'>
      <PageHeader>
        <PageTitle>CADASTRO DE PERFIL</PageTitle>
      </PageHeader>
      <PageSection>
        <Row className='mb-10'>
          <Col className='d-flex flex-dir-col' md={3}>
            <DocLabel htmlFor='profile-name'>Nome do perfil:</DocLabel>
            <DocInput
              type='text'
              className='bold-text'
              name='profile-name'
              onChange={({ target }) => setProfileName(target.value)}
              value={profileName}
            />
          </Col>
          <Col className='d-flex flex-dir-col' md={2}>
            <DocLabel htmlFor='token'>Perfis Hapvida:</DocLabel>
            <AppSelect placeholderText='Perfís' options={profiles} onChange={addProfile} />
          </Col>
          <Col
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <DocLabel htmlFor='token'>Perfis selecionados Sistema Hapvida:</DocLabel>
            <AppBadgeWrapper>
              {addedProfiles.map((item) => (
                <AppBadge key={item.value} onClick={() => removeProfile(item.value)}>
                  <XIcon />
                  &nbsp;
                  <span>{` ${item.value} - ${item.label}`}</span>
                </AppBadge>
              ))}
            </AppBadgeWrapper>
          </Col>
          <Col className='d-flex flex-dir-col' md={1}>
            <DocLabel htmlFor='status'>Status:</DocLabel>
            <AppSelect
              name='status'
              placeholderText='Status'
              options={[
                { label: 'Ativo', value: '1' },
                { label: 'Inativo', value: '0' },
              ]}
              onChange={handleProfileStatusChange}
              value={Number(profileStatus)}
            />
          </Col>
        </Row>
      </PageSection>
      <Row className='h-flex-end mb-18'>
        <Col md={1}>
          <Button
            type='button'
            className='w-100'
            onClick={cancelEdit}
            variant='outline-secondary'
            size='sm'
          >
            Cancelar
          </Button>
        </Col>
        <Col md={1}>
          <Button
            type='button'
            size='sm'
            variant='primary'
            className='w-100'
            onClick={editProfileId ? handleEditProfile : handleSubmit}
          >
            Salvar
          </Button>
        </Col>
      </Row>
      <PageHeader>
        <PageTitle>LISTAGEM DE PERFIS</PageTitle>
      </PageHeader>
      <Row>
        <Col className='d-flex flex-dir-col' md={2}>
          <DocInput
            type='text'
            name='profile-name'
            onChange={({ target }) => setSearchText(target.value)}
            placeholder='Código ou nome do perfil'
            style={{ height: '100%' }}
          />
        </Col>
      </Row>
      <ProfilesTable
        data={filtered.length ? filtered : customProfiles}
        getProfile={handleGetProfile}
      />
    </Block>
  )
}
