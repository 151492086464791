/* eslint-disable react/jsx-no-useless-fragment */
import { format } from 'date-fns';
import React, { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

const StyledTooltip = styled(Tooltip)`
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  border: none;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  offset: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0px;
  overflow: hidden;
  position: fixed !important;
 
  margin-left: -72px !important; 

  && > div:first-child { 
    position: unset !important;
    transform: none !important;
  }

  && > div:first-child::before  { 
    border-bottom-color: #868686;
    bottom: 15px;
    position: relative !important;
  }

  && > div:last-child {
    background-color: #868686;
    text-align: left;
  }
  
  && h6 {
    margin-bottom: 4px;
    font-weight: bold;
    color: #fff;
    font-size: 15px;
  }
`;

const Label = styled.span`
  color: #fff;
  font-size: 13px;
`

const Info = styled.div`
  margin-bottom: 8px;
`

interface CustomTooltipProps {
  children: React.ReactElement;
  statusRequest: any;
}

export const CustomTooltipRt: FC<CustomTooltipProps> = ({
  statusRequest,
  children }
) => {

  const isTyped = statusRequest.find(obj => obj.statusDescription.toLowerCase() === 'tipado');

  if (!isTyped) {
    return <>{children}</>
  }

  return (
    <span id="content">
      <OverlayTrigger
        placement="bottom-start"
        overlay={
          <StyledTooltip
            id="tooltip-id"
            style={{ display: !isTyped && 'none' }}
          >
            <Info>
              <Label>Data/Hora tipagem</Label>
              <h6>{format(new Date(isTyped.registrationDate), 'dd/MM/yyyy  HH:mm')}</h6>
            </Info>
            <Info>
              <Label>Tempo p/ tipagem</Label>
              <h6>{isTyped.timeForTipyng}</h6>
            </Info>
          </StyledTooltip>
        }
      >
        {children}
      </OverlayTrigger>
    </span>

  )
};


