import { FC } from 'react'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { TableStyles } from './styles'
import { ProfilesTableTypes } from './types'

const headers = ['Código', 'Nome do perfil', 'Status', 'Visualizar/Editar']

export const ProfilesTable: FC<ProfilesTableTypes> = ({ data, getProfile }) => (
  <TableStyles className='profiles-table'>
    <thead>
      <tr>
        {headers.map((item) => (
          <th key={item}>{item}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.description}</td>
          <td>{item.statusFlag ? 'Ativo' : 'Inativo'}</td>
          <td style={{ textAlign: 'center' }}>
            <button
              style={{ width: '100%' }}
              className='table-icon-btn'
              type='button'
              onClick={() => getProfile(item.id)}
            >
              <EditIcon />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </TableStyles>
)
