import { FC, useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import { ActionsDropdown, CustomTooltipRt } from '..'
import { ReactComponent as CanceledIcon } from '../../assets/icons/cancelado.svg'
import { ReactComponent as RequestedIcon } from '../../assets/icons/solicitado.svg'
import { ReactComponent as StatusLoadingIcon } from '../../assets/icons/status-loading.svg'
import { ReactComponent as OkIcon } from '../../assets/icons/validado.svg'
import { DASHBOARD_TABLE_HEADERS } from '../../views/home/utils'
import { SubtilesBar, SubtitleLabel } from '../subtitlesBar/styles'
import { TableStyles } from './styles'
import { TransfusionRequestTablePropTypes } from './types'

export const TransfusionRequestTable: FC<TransfusionRequestTablePropTypes> = ({
  onSelect,
  data,
  pages,
  setPage,
  currentPage = '0',
}) => {
  const [sorted, setSorted] = useState<any[]>([])

  const renderStatusIcon = (value, columnType?) => {
    if (value === 'validado' || value === 'tipado') {
      return <OkIcon />
    }
    if (value === 'solicitado') {
      return <RequestedIcon />
    }

    if (value === 'cancelado') {
      return <CanceledIcon />
    }

    if (value === '' && columnType === "tip") {
      return <StatusLoadingIcon />
    }

    return ''
  }


  const doSortColumns = (list: { transfusionRequest: string }[], label = 'modalityTransfName') => {
    const sortedList = [...list].sort((prevItem, currentItem) => {

      const prevItemValue = prevItem.transfusionRequest[label];
      const currentItemValue = currentItem.transfusionRequest[label]

      if (prevItemValue !== currentItemValue) {
        return prevItemValue < currentItemValue ? -1 : 1
      }

      return 0
    })

    return sortedList
  }

  const sortColumns = (label: string) => {
    setSorted((cur) => doSortColumns(cur, label))
  }

  const renderStatusColumn = (item, index: 'sl' | 'tip') => (
    <span id={index}>
      {renderStatusIcon(
        item
          .filter(({ columnType }) => columnType === index)
          .slice(-1)[0]
          ?.statusDescription.toLowerCase() || '',
        index,
      )}
    </span>
  )

  useEffect(() => {
    setSorted(doSortColumns([...data]))
  }, [data])

  return (
    <>
      <TableStyles>
        <thead>
          <tr>
            {DASHBOARD_TABLE_HEADERS.map((item) => (
              <th key={item.value} onClick={() => sortColumns(item.value)}>
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='request-table-body'>
          {sorted.map((rt) => {
            const { statusRequestDto, transfusionRequest } = rt

            return (
              <tr key={rt.transfusionRequest.id}>
                <td className='status-col'>{renderStatusColumn(statusRequestDto, 'sl')}</td>
                <td className='status-col'>
                  <CustomTooltipRt key={transfusionRequest.id} statusRequest={statusRequestDto}>
                    {renderStatusColumn(statusRequestDto, 'tip')}
                  </CustomTooltipRt>
                </td>
                <td style={{ textAlign: 'center' }}>{transfusionRequest.id}</td>
                <td style={{ textAlign: 'center' }}>{transfusionRequest.attendanceCode}</td>
                <td>{transfusionRequest.patientName}</td>
                <td>{transfusionRequest.attendanceType}</td>
                <td>{transfusionRequest.modalityTransfName}</td>
                <td>{transfusionRequest.unityName}</td>
                <td>{transfusionRequest?.station || ''}</td>
                <td>
                  <ActionsDropdown onSelect={(action: string) => onSelect(action, rt)} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </TableStyles>
      <span style={{ padding: 0, fontSize: 12 }}>
        {data.length ? `Resultados encontrados: ${data.length}` : 'Nenhum resultado encontrado.'}
      </span>
      {pages?.length > 1 ? (
        <Pagination size='sm' style={{ justifyContent: 'center', marginBottom: 0, zIndex: '0' }}>
          {pages.map((page) => (
            <Pagination.Item
              key={page}
              onClick={() => {
                setPage(page.toString())
              }}
              active={page.toString() === currentPage}
              activeLabel=''
            >
              {page + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      ) : null}
      <SubtilesBar>
        <SubtitleLabel>Legenda:</SubtitleLabel>
        <SubtitleLabel className='done'>Concluído</SubtitleLabel>
        <SubtitleLabel className='in-progress'>Em andamento</SubtitleLabel>
        <SubtitleLabel className='danger'>Cancelado</SubtitleLabel>
        <SubtitleLabel className='stand-by'>Não iniciado</SubtitleLabel>

        {/* <SubtitleLabel className='done-obs'>Concluído com intercorrência</SubtitleLabel> */}
        {/* <SubtitleLabel className='late'>Em atraso</SubtitleLabel>
      <SubtitleLabel className='stand-by'>Não iniciado</SubtitleLabel> */}
      </SubtilesBar>
    </>
  )
}
