import { FC } from 'react'
import { BlockStyles, BlockTitle } from './styles'
import { BlockTypes } from './types'

export const Block: FC<BlockTypes> = ({ title, children, className = '' }) => (
  <BlockStyles className={className}>
    {title ? <BlockTitle>{title}</BlockTitle> : null}
    {children}
  </BlockStyles>
)
