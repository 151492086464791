import { FC } from 'react'
import { TableStyles } from './styles'
import { TablesPropTypes } from './types'

export const CheckingHemocomponentTable: FC<TablesPropTypes> = ({ headers, data }) => (
  <TableStyles>
    <thead>
      <tr>
        {headers.map((item) => (
          <th key={item}>{item}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <tr key={item}>
          <td>{item}</td>
          <td>{item}</td>
          <td>{item}</td>
          <td>{item}</td>
          <td>{item}</td>
          <td>{item}</td>
          <td>{item}</td>
        </tr>
      ))}
    </tbody>
  </TableStyles>
)
