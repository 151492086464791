import { ChangeEvent, FC, useEffect, useState } from 'react'
import { FormInput } from '../form/styles'
import { Suggestions } from './styles'

export const Autocomplete: FC<{
  suggestions: string[]
  getValue: (val: string) => void
  defaultValue: string
  placeholder: string
  disabled?: boolean
  currentTarget: string
  height?: number
}> = ({
  suggestions,
  getValue,
  defaultValue,
  placeholder = '',
  disabled = false,
  currentTarget = '',
  height = 0,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [userInput, setUserInput] = useState('')

  const searchValue = (value, fromDefault = false) => {
    const tempFilteredSuggestions = suggestions.filter((suggestion) =>
      suggestion
        .toLowerCase()
        .includes(fromDefault ? value.toLowerCase() : userInput.toLowerCase()),
    )

    if (fromDefault && value && tempFilteredSuggestions.length) {
      setFilteredSuggestions([])
      setActiveSuggestion(0)
      setUserInput(tempFilteredSuggestions[0])
      return
    }
    setUserInput(value)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempUserInput = e.target.value

    // Filter our suggestions that don't contain the user's input
    setUserInput(tempUserInput)
  }

  const onClick = (e) => {
    setFilteredSuggestions([])
    setActiveSuggestion(0)
    setShowSuggestions(false)
    setUserInput(e.currentTarget.innerText)
  }

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0)
      setShowSuggestions(false)
      setUserInput(filteredSuggestions[activeSuggestion])
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return
      }
      setActiveSuggestion(-1)
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return
      }
      setActiveSuggestion((cur) => cur + 1)
    }
  }

  const onBlur = () => {
    setShowSuggestions(false)
    setFilteredSuggestions([])
    setUserInput('')
  }

  useEffect(() => {
    getValue(userInput)
  }, [userInput])

  useEffect(() => {
    searchValue(defaultValue || '', true)
  }, [defaultValue])

  useEffect(() => {
    if (filteredSuggestions.length && !userInput.includes('-')) {
      setShowSuggestions(true)
      return
    }

    setShowSuggestions(false)
  }, [filteredSuggestions, userInput])

  useEffect(() => {
    const tempFilteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(userInput.toLowerCase()),
    )
    setFilteredSuggestions(tempFilteredSuggestions)
    setActiveSuggestion(0)
  }, [userInput, suggestions])

  useEffect(() => {
    if (currentTarget === 'clear') {
      onBlur()
    }
  }, [currentTarget])

  return (
    <>
      <FormInput
        type='text'
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        placeholder={placeholder}
        disabled={disabled}
        height={height}
      />
      {showSuggestions && userInput ? (
        <Suggestions onBlur={onBlur} onMouseLeave={onBlur}>
          {filteredSuggestions.map((suggestion, index) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
            <li
              className={index === activeSuggestion ? 'suggestion-active' : ''}
              key={suggestion}
              onClick={onClick}
            >
              {suggestion}
            </li>
          ))}
        </Suggestions>
      ) : null}
    </>
  )
}

export default Autocomplete
