import { FC, useLayoutEffect } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import JsBarcode from 'jsbarcode';
import { differenceInYears } from 'date-fns';
import { toast } from 'react-toastify';
import { ModalPropTypes, PatientData } from '../../types'
import { SubTitle } from '../page/styles'
import { InfoBlock, Label, SubHeaderStampModal, Text, TypingStampModalStyles } from './styles'
import { getField } from '../../utils';
import { getTransfusionRequestTyping } from '../../service';

interface TypingStampModalTypes extends ModalPropTypes {
  patientData: PatientData
}

export const TypingStampModal: FC<TypingStampModalTypes> = ({ show, patientData, handleClose }) => {

  useLayoutEffect(() => {
    if (patientData) {
      JsBarcode("#barcode", getField('attendanceCode', patientData?.transfusionRequest), {
        format: 'ITF',
        width: 2.35,
        height: 56,
        displayValue: false
      });
    }
  }, [patientData])

  const patientOldYears = differenceInYears(new Date(), new Date(patientData?.transfusionRequest?.patientBornDate || ""))

  if (!patientData) {
    return <TypingStampModalStyles show={show} />
  }

  const handleTyping = async () => {
    try {
      const { data } = await getTransfusionRequestTyping(patientData.transfusionRequest.id);

      if (data.content === "RT already typed") {
        toast.error('RT já tipada!')
      } else if (data.content === "Validate RT before typing") {
        toast.error('É preciso validar a RT para realizar a tipagem!')
      } else {
        toast.success('Rt tipada com sucesso!')
      }

    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')

    }

    handleClose()
  }

  return (
    <TypingStampModalStyles show={show} onHide={handleClose}>
      <Modal.Body>
        <SubHeaderStampModal>
          <SubTitle>DADOS PARA O PACIENTE - TIPAGEM</SubTitle>
        </SubHeaderStampModal>
        <InfoBlock>
          <Row>
            <Col md={8}>
              <Label>Nome do Paciente:</Label>
              <Text className='bold-text'>{getField('patientName', patientData?.transfusionRequest)}</Text>
            </Col>
            <Col>
              <Label>Nº Atendim.:</Label>
              <Text className='bold-text'>{getField('attendanceCode', patientData?.transfusionRequest)}</Text>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Data de Nasc.:</Label>
              <Text className='bold-text'>{new Date(getField('patientBornDate', patientData?.transfusionRequest)).toLocaleDateString()}</Text>
            </Col>
            <Col md={3}>
              <Label>Idade:</Label>
              <Text className='bold-text'>{patientOldYears > 1 ? `${patientOldYears} anos` : `${patientOldYears} ano`}</Text>
            </Col>
            <Col md={3}>
              <Label>Leito:</Label>
              <Text className='bold-text' />
            </Col>
            <Col md={3}>
              <Label>Data da Coleta:</Label>
              <Text className='bold-text'>{new Date().toLocaleDateString()}</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Unidade Hospitalar:</Label>
              <Text className='bold-text'>{getField('unityName', patientData?.transfusionRequest)}</Text>
            </Col>
            <Col>
              <Label>Responsável Coleta:</Label>
              <Text className='bold-text'>{getField('collectorName', patientData)}</Text>
            </Col>
          </Row>
        </InfoBlock>
      </Modal.Body>
      <Modal.Footer>
        <Row style={{ width: '100%' }}>
          <Col sm="6" md="6" className="p-0">
            <div className='barcode-block'>
              <svg id="barcode" />
            </div>
          </Col>
          <Col sm="6" className="d-flex align-items-sm-center p-0" style={{ justifyContent: 'flex-end' }} md="6">
            <Row className="justify-content-md-center ">
              <Col >
                <Button size='sm' variant='outline-secondary' onClick={handleClose}>
                  Fechar
                </Button>
              </Col>
              <Col >
                <Button onClick={handleTyping} size='sm' variant='primary' >
                  Imprimir
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Footer>
    </TypingStampModalStyles>
  )
}
