import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { NavList } from '../navList'
import {
  SideBarCloseBtn,
  SideBarDetails,
  SideBarInfo,
  SideBarStyles,
  SideBarThumb,
  SideBarUserEmail,
  SideBarUserName,
} from './styles'
import { SideBarPropTypes } from './types'

export const SideBar = ({ isMenuOpen, closeMenu }: SideBarPropTypes) => (
  <SideBarStyles isMenuOpen={isMenuOpen}>
    <SideBarCloseBtn type='button' onClick={() => closeMenu()}>
      <CloseIcon />
    </SideBarCloseBtn>
    <SideBarInfo>
      <SideBarThumb type='button'>
        <h5>AD</h5>
      </SideBarThumb>
      <SideBarDetails>
        <SideBarUserName>Administrador</SideBarUserName>
        <SideBarUserEmail>admin@hapvida.com.br</SideBarUserEmail>
      </SideBarDetails>
    </SideBarInfo>
    <NavList />
  </SideBarStyles>
)
